var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.layer)?_c('div',[_c('v-card-actions',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-spacer'),_c('action-toolbar',{attrs:{"actions":_vm.actions,"bottom":true},on:{"download":_vm.exportCsv,"history":_vm.openHistory,"removeAll":function($event){return _vm.$refs.confirmationDialog.open(_vm.layer)}}})],1),_c('v-card-actions',[_c('span',{staticStyle:{"width":"180px"}},[_c('v-select',{staticClass:"group-input-left layer-poi-property-filter",attrs:{"items":_vm.filters,"dense":"","hide-details":"","item-text":"name","label":"Фильтр","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("mdi-filter")])]},proxy:true}],null,false,1799049546),model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}})],1),_c('span',{staticStyle:{"width":"180px"}},[(_vm.filterBy.type === 'LIST' || _vm.filterBy.type === 'BOOLEAN')?_c('v-select',{staticClass:"group-input-right",attrs:{"items":_vm.getOptions(),"clearable":"","dense":"","hide-details":"","label":"Значение","outlined":"","single-line":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.filterBy.type === 'BOOLEAN')?_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]):_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.filterBy.type === 'BOOLEAN')?_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]):_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item))])]}}],null,false,3375151452),model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}}):_c('v-text-field',{staticClass:"group-input-right",attrs:{"clearable":"","dense":"","hide-details":"","label":"Значение","outlined":""},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}})],1),_c('v-spacer'),(_vm.layer.poiTemplates.length > 1)?_c('v-select',{attrs:{"items":_vm.layer.poiTemplates,"dense":"","hide-details":"","item-text":"name","label":"Тип объектов","outlined":"","return-object":""},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}}):_vm._e()],1),_c('v-card-text',{staticClass:"ma-0 pa-0",staticStyle:{"overflow":"auto"}},[_c('v-data-table',{style:({paddingBottom: _vm.displayXS ? '56px' : ''}),attrs:{"id":!_vm.displayXS ? 'layer-poi-table-fixed' : '',"headers":_vm.headers.filter(function (f) { return !f.excludeFromTableView; }),"items":_vm.items,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"options":_vm.options,"server-items-length":_vm.totalElements,"locale":"ru"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',{on:{"click":function($event){return _vm.openDialog(item)}}},_vm._l((headers),function(header,index){return _c('td',{key:index},[(header.type === 'LINK')?[_c('a',{attrs:{"href":item.properties[header.value],"target":"_blank"}},[_vm._v(" "+_vm._s(item.properties[header.value])+" ")])]:(header.type === 'NUMBER' ||  header.type === 'COMPUTED_FIELD')?[_vm._v(" "+_vm._s(item.properties[header.value] ? item.properties[header.value].toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') : '')+" ")]:(header.type === 'CUSTOM_LINK')?[_c('a',{attrs:{"href":item.properties[header.options] + item.properties[header.value],"target":"_blank"}},[_vm._v(" "+_vm._s(item.properties[header.value])+" ")])]:(header.type === 'PARAMETRIC_LINK')?[_c('v-hover',{attrs:{"open-delay":"700"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticStyle:{"position":"relative"}},[_c('v-menu',{attrs:{"value":hover,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":_vm.createParametricLink(header.options, item.properties),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('link'))+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.createParametricLink(header.options, item.properties))+" ")])],1)],1)],1)]}}],null,true)})]:(header.type === 'BOOLEAN')?[_vm._v(" "+_vm._s(item.properties[header.value] ? _vm.$t('true') : _vm.$t('false'))+" ")]:[_vm._v(" "+_vm._s(item.properties[header.value])+" ")]],2)}),0)]}}],null,false,1203355013)})],1)],1):_vm._e(),_c('confirmation-dialog',{ref:"confirmationDialog",attrs:{"title":"<span class='red--text font-weight-bold'>Внимание, это действие необратимо!</span><br/>Вместе с этой операцией история изменений по объектам также будет удалена.<br/> Вы действительно хотите удалить все данные типа: "},on:{"confirm":_vm.removeAll}}),_c('layer-poi-dialog',{ref:"layerPoiDlg",attrs:{"actions-toolbar":"","map-widget":""},on:{"update":_vm.loadPoiList}}),_c('layer-poi-changelog-table',{ref:"layerPoiChangelogTable"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }