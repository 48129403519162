<template>
  <vl-map
    id="map"
    class="d-map"
    ref="map"
    data-projection="EPSG:4326"
    @created="mapCreated"
    load-tiles-while-animating
    load-tiles-while-interacting
  >
    <vl-view
      :zoom="zoom"
      :center="center"
      ref="view"
    />
    <div v-if="selectedBaseLayers.length">
      <xyz-layer
        v-for="layer in selectedBaseLayers"
        :key="layer.id"
        :layer="layer"
      />
    </div>
    <base-layer v-if="!selectedBaseLayers.length"/>
    <vl-layer-vector :z-index="100">
      <vl-source-vector :features="features">
        <vl-style-func :factory="getStyle"/>
      </vl-source-vector>
    </vl-layer-vector>
  </vl-map>
</template>

<script>
import { createFillStyle, createStyle, findPointOnSurface } from 'vuelayers/lib/ol-ext'
import XyzLayer from '@/components/map/layer/XyzLayer.vue'
import BaseLayer from '@/components/map/BaseLayer'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'

export default {
  name: 'LayerPoiMapViewer',
  components: { BaseLayer, XyzLayer },
  props: ['poi', 'selectedBaseLayers'],
  data: () => ({
    features: [],
    center: [0, 0],
    zoom: 2
  }),
  created () {
    this.features = [{
      type: 'Feature',
      geometry: this.poi.geometry,
      properties: {
        color: this.poi.template.style
      }
    }]
    this.poi.type = 'Feature'
    let source = new Vector({
      features: (
        new GeoJSON({
          featureProjection: 'EPSG:3857'
        }))
        .readFeatures(this.poi)
    })
    if (this.poi.geometry.type === 'Point') {
      this.center = this.poi.geometry.coordinates
      this.zoom = 12
    } else {
      setTimeout(() => {
        this.$refs.view.fit(source.getExtent(), { duration: 500 })
      }, 500)
    }
  },
  methods: {
    mapCreated () {
      this.center = findPointOnSurface(this.poi.geometry)
    },
    getStyle () {
      let { template } = this.poi
      let style = template.defaultLayerPoiStyle
      return function () {
        const hexToRgb = (hex, alpha = 0.5) => {
          let r = parseInt(hex.slice(1, 3), 16)
          let g = parseInt(hex.slice(3, 5), 16)
          let b = parseInt(hex.slice(5, 7), 16)
          return [r, g, b, alpha]
        }

        return createStyle({
          strokeColor: style.stroke,
          strokeWidth: style.strokeWidth,
          imageRadius: 6,
          fill: createFillStyle({
            fillColor: hexToRgb(style.fill)
          })
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
