<template>
  <tr :style="rowStyle" style="position: relative" @click="openEtItemDialog(item)">
    <td v-if="primaryHeader && setAbility('MODERATOR')" class="px-3" @click.stop>
      <v-checkbox
        v-model="isSelected"
        class="ml-2"
        hide-details
        @change="$emit('updateSelected')"
      />
    </td>
    <td
      v-for="header in headersWithComments"
      :key="header.id"
      :style="getCellStyle(header)"
      class="et-viewer-item-td"
    >
      <v-tooltip v-if="header.comment" bottom color="black" max-width="90vh" transition="none">
        <template v-slot:activator="{on}">
          <div class="et-viewer-item-comment" v-on="on"></div>
        </template>
        {{ header.comment }}
      </v-tooltip>
      <v-icon v-if="header.fieldType  === 'BOOLEAN'">
        {{ item[header.alias] ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
      <div
        v-else
        class="d-flex"
        style="max-height: 126px!important; overflow: hidden"
      >
        <span
          :class="header.hasAdditionalString ? 'simple-link pointer' : ''"
          class="full-width"
          @click="e =>openLink(header, item, e)"
          v-html="getItemText(header, item)"
        />
      </div>
    </td>
    <slot name="additional-column"/>
  </tr>
</template>

<script>
import { getStyleForRow, getStyleFromHeader } from '@/components/et/view-settings/utils'
import moment from 'moment/moment'

export default {
  name: "EtViewerItem",
  props: {
    item: Object,
    headers: Array,
    rules: Array,
    commentHeaders: Array,
    isSelected: Boolean,
    primaryHeader: Object
  },
  methods: {
    getItemText (header, item) {
      let result
      if (header.fieldType === 'DATE') {
        result = moment(item[header.alias], 'YYYY-MM-DD', true).isValid()
          ? moment(item[header.alias], 'YYYY-MM-DD').format('DD.MM.YYYY')
          : item[header.alias] || ''
      } else {
        result = item[header.alias] || ''
      }
      if (header.hasAdditionalString && header.additionalString) {
        result = header.additionalString + result
      }
      return result
    },
    openEtItemDialog (item) {
      if (!window.getSelection().toString()) {
        this.$emit('openEtiItemDialog', item)
      }
    },
    getCellStyle (header) {
      return getStyleFromHeader(header, this.rules, this.item[header.alias])
    },
    openLink (header, item, e) {
      if (header.hasAdditionalString) {
        window.open(this.getItemText(header, item), '_blank')
        e.stopPropagation()
        e.preventDefault()
      } else if (window.getSelection().toString()) {
        e.stopPropagation()
        e.preventDefault()
      }
    },
  },
  computed: {
    rowStyle () {
      return getStyleForRow(this.rules, this.headers, this.item)
    },
    headersWithComments () {
      return this.headers.map(it => {
        const header = JSON.parse(JSON.stringify(it))
        const commentHeaders = this.commentHeaders.filter(el => el.commentField && el.commentField.id === header.id)
        if (commentHeaders.length) {
          header.comment = commentHeaders
            .filter(commentHeader => !!this.item[commentHeader.alias])
            .map(commentHeader => this.item[commentHeader.alias]).join(' | ')
        }
        return header
      })
    }
  }
}
</script>

<style>
.et-viewer-item-td {
  position: relative;
  max-width: 300px;
}

.et-viewer-item-comment {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 15px solid #7ab1f4;
  border-left: 15px solid transparent;
}
</style>
