<template>
  <div>
    <v-select
      v-if="equalsMode"
      ref="stringHeaderFilter"
      v-model="filter[getIndexOfFilter(header.alias)].value"
      :items="selectStrings"
      :no-data-text="$t('noResults')"
      class="mt-1"
      dense
      hide-details
      menu-props="offsetY, bottom"
      multiple
      outlined
    >
      <template v-slot:append>
        <v-btn
          v-if="filter[getIndexOfFilter(header.alias)].fieldValue || filter[getIndexOfFilter(header.alias)].value[0]"
          elevation="0"
          icon
          small
          style="margin-top: -1px"
          @click="clear"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
        <div v-else class="pt-1">
          <v-icon small>mdi-filter</v-icon>
        </div>
      </template>
      <template v-slot:selection="{item, index}">
        <v-chip v-if="index === 0" label>
          {{ filter[getIndexOfFilter(header.alias)].value.length }}
        </v-chip>
      </template>
      <template v-slot:prepend-item>
        <v-text-field
          :value="filter[getIndexOfFilter(header.alias)].fieldValue"
          class="mx-3 pt-3 mb-2"
          clearable
          dense
          hide-details
          solo
          @input="changeFieldValue"
        />
      </template>
    </v-select>
    <div v-else style="width: 100%; position: relative">
      <v-text-field
        ref="stringHeaderFilter"
        :value="filter[getIndexOfFilter(header.alias)].fieldValue"
        class="mt-1"
        dense
        hide-details
        multiple
        outlined
        @focus="stringLikeHeaderMenu = true"
        @focusout="focusout"
        @input="changeFieldValue"
      >
        <template v-slot:append>
          <v-btn
            v-if="filter[getIndexOfFilter(header.alias)].fieldValue
              || filter[getIndexOfFilter(header.alias)].value[0]
              || !filter[getIndexOfFilter(header.alias)].selectAll
              || !filter[getIndexOfFilter(header.alias)].includeEmpty
            "
            elevation="0"
            icon
            small
            style="margin-top: -1px"
            @click="clear"
          >
            <v-icon color="primary">close</v-icon>
          </v-btn>
          <div v-else class="pt-1">
            <v-icon small>mdi-filter</v-icon>
          </div>
        </template>
        <template v-slot:prepend-inner>
          <v-chip
            v-if="filter[getIndexOfFilter(header.alias)].value.length
            || !filter[getIndexOfFilter(header.alias)].selectAll
            || !filter[getIndexOfFilter(header.alias)].includeEmpty"
            small
          >
            {{ likeFilterInnerText }}
          </v-chip>
        </template>
      </v-text-field>
      <div v-if="stringLikeHeaderMenu" class="et-string-like-filter-menu">
        <v-card class="pa-0 ma-0 string-like-header-menu-list d-flex flex-column" max-height="400">
          <v-card-text class="overflow-y-auto pa-0 ma-0">
            <v-list>
              <v-list-item class="string-like-header-menu-list-item" @click="changeSelectAll()">
                <v-list-item-action class="mr-1">
                  <v-checkbox :input-value="selectAllValue"/>
                </v-list-item-action>
                <v-list-item-content>{{ $t('selectAll') }}</v-list-item-content>
              </v-list-item>
              <v-tooltip
                v-for="string in selectStrings"
                :key="string"
                bottom
                color="black"
                max-width="90vw"
                open-delay="1000"
              >
                <template v-slot:activator="{on}">
                  <v-list-item
                    class="string-like-header-menu-list-item"
                    @click="changeSelectedString(string)"
                    v-on="on"
                  >
                    <v-list-item-action class="mr-1">
                      <v-checkbox :input-value="isStringSelected(string)"/>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0" style="text-align: start; max-height: 40px">
                      {{ string }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                {{ string }}
              </v-tooltip>
            </v-list>
          </v-card-text>
          <v-card-actions class="elevation-1 ma-0 pa-0">
            <v-list-item class="string-like-header-menu-list-item" @click="changeIncludeEmpty()">
              <v-list-item-action class="mr-1">
                <v-checkbox :input-value="filter[getIndexOfFilter(header.alias)].includeEmpty"/>
              </v-list-item-action>
              <v-list-item-content>{{ $t('includeEmpty') }}</v-list-item-content>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import messages from '@/componet-locale/et-filter/messages'
import { CONDITIONS } from '@/components/et/view-settings/utils'
import { EventBus } from '@/event-bus'

export default {
  name: 'StringHeaderFilter',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filter: Array,
    getIndexOfFilter: Function
  },
  data: () => ({
    items: [],
    stringLikeHeaderMenu: false,
    filterMenu: false
  }),
  mounted () {
    this.updateAutocomplete()
    EventBus.$on('updateAutocompletes', this.updateAutocomplete)
  },
  beforeDestroy () {
    EventBus.$off('updateAutocompletes')
  },
  methods: {
    updateAutocomplete () {
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.filter[this.getIndexOfFilter(this.header.alias)].fieldValue || '',
          conditions: this.filter
        })
        .then(({ data }) => {
          this.items = data
        })
        .catch(er => console.error(er.message))
    },
    clear () {
      this.filter[this.getIndexOfFilter(this.header.alias)].fieldValue = ''
      this.filter[this.getIndexOfFilter(this.header.alias)].value = []
      this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = true
      this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty = true
      this.$emit('update')
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    // onClickFilter () { todo RESOLVE MENUS PROBLEM
    //   this.filterMenu = true
    //   this.stringLikeHeaderMenu = false
    //   this.$refs.stringHeaderFilter.isFocused = false
    //   this.$refs.stringHeaderFilter.isMenuActive = false
    //   this.$nextTick(() => {
    //     this.$refs.stringHeaderFilter.isFocused = false
    //     this.$refs.stringHeaderFilter.isMenuActive = false
    //   })
    // },
    changeFieldValue (v) {
      this.filter[this.getIndexOfFilter(this.header.alias)].fieldValue = v
      if (!this.equalsMode) {
        this.filter[this.getIndexOfFilter(this.header.alias)].value = []
        this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = true
      }
    },
    changeSelectAll () {
      this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = !this.selectAllValue
      this.filter[this.getIndexOfFilter(this.header.alias)].value = []
    },
    changeIncludeEmpty () {
      this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty
        = !this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty
    },
    changeSelectedString (string) {
      this.filter[this.getIndexOfFilter(this.header.alias)].value.includes(string)
        ? this.filter[this.getIndexOfFilter(this.header.alias)].value
          .splice(this.filter[this.getIndexOfFilter(this.header.alias)].value.indexOf(string), 1)
        : this.filter[this.getIndexOfFilter(this.header.alias)].value.push(string)
      if (this.filter[this.getIndexOfFilter(this.header.alias)].value.length === this.selectStrings.length
        && this.filter[this.getIndexOfFilter(this.header.alias)].selectAll
      ) {
        this.filter[this.getIndexOfFilter(this.header.alias)].value = []
        this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = false
      }
    },
    isStringSelected (string) {
      if (this.filter[this.getIndexOfFilter(this.header.alias)].selectAll) {
        return !this.filter[this.getIndexOfFilter(this.header.alias)].value.includes(string)
      } else {
        return this.filter[this.getIndexOfFilter(this.header.alias)].value.includes(string)
      }
    },
    focusout (e) {
      if (!!e.relatedTarget &&
        (e.relatedTarget.className.includes('string-like-header-menu-list-item')
          || e.relatedTarget.className.includes('string-like-header-menu-list'))
      ) {
        this.$refs.stringHeaderFilter.focus()
      } else {
        this.stringLikeHeaderMenu = false
      }
    }
  },
  computed: {
    CONDITIONS () {
      return CONDITIONS
    },
    selectAllValue () {
      if (this.filter[this.getIndexOfFilter(this.header.alias)].value.length === 0) {
        return this.filter[this.getIndexOfFilter(this.header.alias)].selectAll
      } else return this.filter[this.getIndexOfFilter(this.header.alias)].value.length === this.selectStrings.length
    },
    selectStrings () {
      const res = this.equalsMode
        ? [
          ...this.filter[this.getIndexOfFilter(this.header.alias)].value,
          ...this.items.map(el => el.value)
        ]
        : this.items.map(el => el.value)
      return res
        .filter((function (item, pos) {
          return res.indexOf(item) === pos;
        }))
        .filter(el => el !== null && el !== '')
    },
    equalsMode () {
      return this.filter[this.getIndexOfFilter(this.header.alias)].predicate === this.CONDITIONS.EQUAL
    },
    likeFilterInnerText () {
      if (this.filter[this.getIndexOfFilter(this.header.alias)].selectAll) {
        return '-' + (this.filter[this.getIndexOfFilter(this.header.alias)].value.length
          + !this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty)
      } else {
        return this.filter[this.getIndexOfFilter(this.header.alias)].value.length
          + this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty
      }
    }
  }
}
</script>

<style>
.et-string-like-filter-menu {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  min-width: 120px
}
</style>
