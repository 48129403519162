<template>
  <v-card
    class="py-2 pb-0"
    outlined
    color="grey lighten-4"
  >
    <v-layout class="pa-1" row wrap>
      <v-list-item class="px-4">
        <v-list-item-content>
          <span class="bold">
            <ad-status-icon
              v-show="showStatus"
              :ad="item"
            />
            {{ item.name }}
          </span>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                outlined
                color="blue darken-4"
              >
                <v-icon small>{{ getIcon(item.dataType) }}</v-icon>
              </v-btn>
              <span
                v-if="docVersions.length > 1"
                class="ml-2"
              >
              {{ docVersions.length }}
              </span>
            </template>
            <span>{{ $t(item.dataType) }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>

      <v-flex
        v-if="!simple"
        class="simple-row-controls"
        lg3 md5 sm5 xl2 xs12
      >
        <v-btn @click="$refs.infoDialog.open(item.id)" icon>
          <v-icon>info</v-icon>
        </v-btn>
        <v-btn @click="share(item)" icon>
          <v-icon>share</v-icon>
        </v-btn>
        <v-btn @click="edit(item)" icon v-if="setAbility('MODERATOR')">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn @click="remove(item)" icon v-if="setAbility('MODERATOR')">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card-text class="simple-data-row-body pt-0 pb-2 px-4" v-html="item.shortDescription.replace(/<[^>]+>/g, '')"/>
    <div class="px-3 mt-2">
      <v-chip
        class="mr-1 mb-1"
        label
        outlined
        v-for="keyword in item.keywords"
      >
        <v-icon x-small>tag</v-icon>
        {{ keyword.value }}
      </v-chip>
    </div>
    <v-layout row wrap>
      <div class="d-inline-block simple-row-author">
        <div class="d-inline-block" v-if="!displayXS">
          <div class="c-text" v-text="item.owner.firstName + ' ' + item.owner.lastName"/>
          <div class="c-text" v-text="formatDate(item.creationDate)"/>
        </div>
      </div>
      <v-spacer class="hidden-xs-only"/>
      <div class="simple-row-footer-controls">
        <v-btn @click="addItem(item)"
               color="primary"
               outlined
               text
               v-if="item.dataType === 'LAYER' && !simple">
          {{ $t('addLayer') }}
        </v-btn>

        <span v-if="item.dataType=== 'DOC'">
          <doc-version-dialog
            :doc="{id: item.id}"
            ref="docVersionDlg"
          />
          <v-btn
            v-if="setAbility('MODERATOR')"
            @click="$refs.docVersionDlg.open()"
            color="primary"
            class="mr-2 hidden-xs-only"
            small
            outlined
          >
            <v-icon left>upload_file</v-icon>
            {{$t('addVersion')}}
          </v-btn>
        </span>

        <v-btn
          @click="$refs.infoDialog.open(item.id)"
          color="primary"
          class="mr-2"
          small
          outlined
        >
          <v-icon left>info_outline</v-icon>
          {{$t('button.info')}}
        </v-btn>

        <v-btn
          @click="$emit('open', item)"
          @keydown.shift="open"
          color="primary"
          small
          outlined
        >
          <v-icon left>launch</v-icon>
          {{ $t('view') }}
        </v-btn>
      </div>
    </v-layout>

    <abstract-data-info-dialog :data="item" ref="infoDialog"/>
  </v-card>
</template>

<script>
import AbstractDataInfoDialog from './AbstractDataInfoDialog'
import messages from '../../componet-locale/simple-data-row/messages'
import DocVersionDialog from '@/components/doc/DocVersionDialog'
import AdStatusIcon from '@/components/utils/AdStatusIcon'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'SimpleDataRow',
  components: { AdStatusIcon, DocVersionDialog, AbstractDataInfoDialog },
  mixins: [displayServiceMixin],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  i18n: { messages },
  data: () => ({
    docVersions: []
  }),
  created () {
    if (this.item.dataType === 'DOC') {
      this.loadDocVersions()
    }
  },
  methods: {
    loadDocVersions () {
      this.$axios
        .get(`/docs/get-all-versions/${this.item.id}`, { timeout: 30000 })
        .then(response => {
          this.docVersions = response.data
        })
    },
    addItem (item) {
      this.$emit('add-item', item)
    },
    remove (item) {
      this.$emit('remove', item)
    },
    edit (item) {
      this.$emit('edit', item)
    },
    share (item) {
      this.$emit('share', item)
    },
    getIcon (type) {
      return this.getUrlByDataType(type).icon
    }
  }
}
</script>

<style>

.simple-row-author .v-avatar {
  vertical-align: bottom !important;
}

</style>
