<template>
  <vl-map
    id="map"
    ref="map"
    class="d-map"
    data-projection="EPSG:4326"
    load-tiles-while-animating
    load-tiles-while-interacting
  >
    <vl-view
      ref="view"
      :center="center"
      :zoom="zoom"
    />

    <base-layer v-if="!selectedBaseLayers.length"/>
    <xyz-layer
      v-for="layer in selectedBaseLayers"
      :key="layer.id"
      :layer="layer"
    />
    <zws-layer :layer="layer" :z-index="2"/>
    <selected-feature :selected="features" :widget="true" small-point/>

  </vl-map>
</template>

<script>
import BaseLayer from '@/components/map/BaseLayer'
import ZwsLayer from '@/components/map/layer/ZwsLayer.vue'
import SelectedFeature from '@/components/map/SelectedFeature.vue'
import XyzLayer from '@/components/map/layer/XyzLayer.vue'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'

export default {
  name: 'ZwsFeatureMapViewer',
  components: { XyzLayer, SelectedFeature, ZwsLayer, BaseLayer },
  props: ['feature', 'selectedBaseLayers', 'layer'],
  data: () => ({
    features: [],
    center: [0, 0],
    zoom: 14
  }),
  created () {
    this.features = [{
      type: 'Feature',
      geometry: this.feature.geometry
    }]
    let source = new Vector({
      features: (
        new GeoJSON({
          featureProjection: 'EPSG:3857'
        }))
        .readFeatures(this.features[0])
    })
    if (this.feature.type === 'Point') {
      this.center = this.feature.coordinates[0]
      this.zoom = 12
    } else {
      setTimeout(() => {
        this.$refs.view.fit(source.getExtent(), { duration: 500 })
      }, 500)
    }
  }
}
</script>
