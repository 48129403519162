import { render, staticRenderFns } from "./LayerPoiMapViewer.vue?vue&type=template&id=0fa5138e&scoped=true&"
import script from "./LayerPoiMapViewer.vue?vue&type=script&lang=js&"
export * from "./LayerPoiMapViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa5138e",
  null
  
)

export default component.exports